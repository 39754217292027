(function () {
	var scrollTop, hide, show, checkPosition, element;
	

		 
	element = document.createElement('a');
	element.id = 'scroll-up-button';
	element.href = '!#';
	element.className = 'hidden-print';
	element.innerHTML = '<i class="material-icons">keyboard_arrow_up</i>';

	document.body.appendChild(element);
	
	setTimeout(function () {
		$(element).on('click', scrollTop);
		$(document).on('scroll', checkPosition);
	}, 100);
	
	$(document).on('resize', function () {

	});
	
	scrollTop = function (e) {
		e.preventDefault();

		var body = $("html, body");
		body.stop().animate({scrollTop:0}, '500', 'swing', function() { 
			
		});
	};
		
	checkPosition = function (e) {
		if ($(document).scrollTop() > window.innerHeight / 3 && window.innerWidth > 768) {
		 $('#scroll-up-button').css('transform', 'translate(0, 0)');
		} else {
			 $('#scroll-up-button').css('transform', 'translate(0, 130%)');
		}
	};
	$('.content a[href^="#"]').on('click', function (e) {
		e.preventDefault();
		var id = $(this).attr('href');
	  var body = $("html, body");
		body.stop().animate({scrollTop: $(id).offset().top}, '500', 'swing', function() { })
	})
}());

